import * as React from "react";
import { SearchPanel } from "./SearchPanel/SearchPanel";
import { ResultsPanel } from "./ResultsPanel/ResultsPanel";
import { PostedSpeedRecord } from "../../gen/clients/vsl";
import { FieldName, Values } from "./SearchPanel/SearchForm";

enum Panel {
  search = "search",
  results = "results"
}

const HomePage: React.FC = () => {
  const [activePanel, setActivePanel] = React.useState<Panel>(Panel.search);
  const [postedSpeedRecords, setPostedSpeedRecords] = React.useState<PostedSpeedRecord[]>();
  const [selectedDirection, setSelectedDirection] = React.useState<string>();

  const onSearchSubmit = React.useCallback((searchFormValues: Values) => {
    setSelectedDirection(searchFormValues[FieldName.direction]);
  }, []);

  const onSearchSuccess = React.useCallback((postedSpeeds: PostedSpeedRecord[]) => {
    setPostedSpeedRecords(postedSpeeds);
    setActivePanel(Panel.results);
  }, []);

  const onReturnToSearch = React.useCallback(() => {
    setActivePanel(Panel.search);
  }, []);

  if (activePanel === Panel.results && postedSpeedRecords) {
    return (
      <ResultsPanel
        postedSpeedRecords={postedSpeedRecords}
        selectedDirection={selectedDirection!}
        onReturnToSearchClick={onReturnToSearch}
      />
    );
  }
  return <SearchPanel onSearchSubmit={onSearchSubmit} onSearchSuccess={onSearchSuccess} />;
};

export { HomePage };
