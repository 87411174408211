/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListVslRoadsResponse } from '../models/ListVslRoadsResponse';
import type { VslRoad } from '../models/VslRoad';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class InfrastructureService {
    /**
     * Returns a list of roads with VSL signs
     * @returns ListVslRoadsResponse Successful response
     * @throws ApiError
     */
    public static listVslRoads({
        state,
    }: {
        state: string,
    }): CancelablePromise<ListVslRoadsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vslRoads',
            query: {
                'state': state,
            },
        });
    }
    /**
     * Returns a given road
     * @returns VslRoad Successful response
     * @throws ApiError
     */
    public static getVslRoad({
        state,
        road,
    }: {
        state: string,
        road: string,
    }): CancelablePromise<VslRoad> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vslRoads/{state}/{road}',
            path: {
                'state': state,
                'road': road,
            },
        });
    }
}
