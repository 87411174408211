export const STATE = "IN";
export const TIME_ZONE = "America/Indiana/Indianapolis";

export const DIRECTION_NAMES = {
  E: "Eastbound",
  W: "Westbound",
  N: "Northbound",
  S: "Southbound"
};

export const ALL_DIRECTIONAL_EXCEPTION_ROADS = ["I-465"];

export const DATE_TIME_PICKER_FORMAT = "YYYY-MM-DD HH:mm:ss";
