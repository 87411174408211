import * as React from "react";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import logoImage from "assets/logo.png";
import { PageLoading } from "../PageLoading/PageLoading";
import { PageError } from "../PageError/PageError";

interface Props {
  isLoading?: boolean;
  error?: unknown;
  containerMaxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  children: React.ReactNode;
}

const PageLayout: React.FC<Props> = ({ isLoading, error, containerMaxWidth, children }) => {
  return (
    <>
      <Box displayPrint="none">
        <AppBar position="relative">
          <Toolbar>
            <Box component="img" src={logoImage} alt="Logo" width={40} height={40} />
          </Toolbar>
        </AppBar>
      </Box>
      <Container maxWidth={containerMaxWidth || "xl"} sx={{ py: 2 }}>
        <>{error && <PageError error={error} />}</>
        {isLoading ? <PageLoading /> : !error && children}
      </Container>
    </>
  );
};

export { PageLayout };
