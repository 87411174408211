import * as React from "react";
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { HistoricDataService, InfrastructureService, PostedSpeedRecord } from "gen/clients/vsl";
import { ALL_DIRECTIONAL_EXCEPTION_ROADS, DATE_TIME_PICKER_FORMAT, STATE, TIME_ZONE } from "constants/constants";
import { PageLayout } from "components/PageLayout/PageLayout";
import { Form, Formik } from "formik";
import { FieldName, SearchForm, Values } from "./SearchForm";
import { ApiErrorMessage } from "../../../components/ApiErrorMessage/ApiErrorMessage";
import { ErrorMessageAlert } from "../../../components/ErrorMessageAlert/ErrorMessageAlert";
import dayjs from "dayjs";

interface Props {
  onSearchSubmit: (values: Values) => void;
  onSearchSuccess: (speedRecords: PostedSpeedRecord[]) => void;
}

const SearchPanel: React.FC<Props> = ({ onSearchSubmit, onSearchSuccess }) => {
  const {
    data: roads,
    isLoading: areRoadsLoading,
    error: roadsError
  } = useQuery(["listRoads"], {
    queryFn: () => InfrastructureService.listVslRoads({ state: STATE })
  });

  const {
    mutateAsync: findSpeedRecords,
    data: speedRecords,
    error: speedRecordsError
  } = useMutation(HistoricDataService.findPostedSpeedRecords);

  const validate = (values: Values) => {
    const errors: Partial<Record<FieldName, string>> = {};
    const timestampFieldValue = values[FieldName.timestamp];
    if (timestampFieldValue) {
      const timestampDate = dayjs.tz(timestampFieldValue, DATE_TIME_PICKER_FORMAT, TIME_ZONE).toDate();
      const now = new Date();
      if (now.getTime() < timestampDate.getTime()) {
        errors[FieldName.timestamp] = "Date cannot be in the future";
      }
    }
    return errors;
  };

  const onSubmit = async (values: Values) => {
    const road = values[FieldName.road];
    let direction = values[FieldName.direction];
    const mileMarker = +values[FieldName.mileMarker];
    const timestampFieldValue = values[FieldName.timestamp];
    const timestampIso = dayjs.tz(timestampFieldValue, DATE_TIME_PICKER_FORMAT, TIME_ZONE).toISOString();
    // According to requirements, for some roads, we should allow to select any direction and treat N as E, and S as W.
    if (ALL_DIRECTIONAL_EXCEPTION_ROADS.includes(road)) {
      if (direction === "N") {
        direction = "E";
      } else if (direction === "S") {
        direction = "W";
      }
    }
    onSearchSubmit(values);

    await findSpeedRecords(
      { state: STATE, road, direction, mileMarker, timestamp: timestampIso },
      {
        onSuccess: data => {
          if (data.length > 0) {
            onSearchSuccess(data);
          }
        }
      }
    );
  };

  return (
    <PageLayout isLoading={(areRoadsLoading || !roads) && !roadsError} error={roadsError}>
      <Box padding={3}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={5}>
            <Stack spacing={1}>
              <Typography variant="h6">Indiana DOT Variable Speed Limit Search Tool</Typography>
              <Typography>
                In August 2024, the Indiana Department of Transportation (INDOT) began implementing Variable Speed
                Limits (VSLs) on portions of I-465 around Indianapolis. These VSLs allow INDOT to raise and lower the
                speed limit based on current weather and traffic conditions to enhance highway safety. There are plans
                to extend VSLs to additional highways in Indiana in the future.
              </Typography>

              <Typography>
                <Box fontWeight="fontWeightMedium">Important Information:</Box>
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightMedium" display="inline">
                  - Enforceable Speed Limits:
                </Box>{" "}
                The speed limit displayed on the VSL sign is legally enforceable. You can receive a speeding ticket for
                exceeding this limit.
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightMedium" display="inline">
                  - Standard Speed Limit:
                </Box>{" "}
                If the VSL signs are not functioning or displaying a limit, the standard speed limit of 55 mph applies.
              </Typography>

              <Typography>
                This website provides a tool to search the historical posted speed limits for locations on I-465 with
                VSLs. To use this tool, enter the following details:
                <Box fontWeight="fontWeightMedium">- Date & Time</Box>
                <Box fontWeight="fontWeightMedium">- Mile Marker</Box>
                <Box fontWeight="fontWeightMedium">- Direction of Travel</Box>
              </Typography>

              <Typography>
                This information can help you verify the posted speed limit at a specific time and location. If you have
                received a speeding ticket, the mile marker and direction of travel should be noted on your ticket.
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightMedium" display="inline">
                  Note:
                </Box>{" "}
                VSL data is available starting from August 9, 2024. Currently, it covers only portions of I-465.
              </Typography>
            </Stack>
          </Grid>
          <Grid item={true} xs={12} md={7}>
            {roads && (
              <Card>
                <CardContent>
                  <Formik
                    initialValues={{
                      [FieldName.road]: roads[0],
                      [FieldName.direction]: "",
                      [FieldName.mileMarker]: "",
                      [FieldName.timestamp]: ""
                    }}
                    validate={validate}
                    onSubmit={onSubmit}
                  >
                    <Form autoComplete="off" noValidate={true}>
                      <SearchForm roads={roads} />
                      <>
                        {(speedRecordsError || speedRecords?.length === 0) && (
                          <>
                            {speedRecordsError && <ApiErrorMessage apiError={speedRecordsError} />}
                            {speedRecords?.length === 0 && <ErrorMessageAlert>No results found</ErrorMessageAlert>}
                          </>
                        )}
                      </>
                    </Form>
                  </Formik>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
};

export { SearchPanel };
