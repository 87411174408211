import * as React from "react";
import { useQueries } from "react-query";
import { HistoricDataService, PostedSpeedRecord } from "gen/clients/vsl";
import { DateRange } from "../../../../types/DateRange";
import { findIntersections, formatDate } from "../../../../utils/DateUtils";
import { Alert, AlertTitle, Typography } from "@mui/material";

interface Props {
  postedSpeedRecords: PostedSpeedRecord[];
}

const OutageListing: React.FC<Props> = ({ postedSpeedRecords }) => {
  const queryOptions = React.useMemo(
    () =>
      postedSpeedRecords
        .filter(r => !r.isOutage)
        .map(r => ({
          queryKey: ["listSignOutages", r.vslSignId, r.postedAt, r.removedAt],
          queryFn: () => HistoricDataService.listSignOutages({ signId: r.vslSignId, from: r.postedAt, to: r.removedAt })
        })),
    [postedSpeedRecords]
  );
  const queryResults = useQueries(queryOptions);
  const { outagePeriods, individualOutageCount, isError } = React.useMemo(() => {
    const areAllQueriesCompleted = queryResults.every(r => r.isSuccess || r.isError);
    if (!areAllQueriesCompleted) {
      return {
        outagePeriods: [],
        isError: false,
        individualOutageCount: 0
      };
    }
    const successfulQueries = queryResults.filter(r => r.isSuccess);
    const outageDateRanges: DateRange[] = successfulQueries
      .flatMap(r => r.data)
      .map(timePeriod => ({
        start: new Date(timePeriod!.from),
        end: timePeriod!.to ? new Date(timePeriod!.to) : undefined
      }));
    // Add periods from posted records with outages
    outageDateRanges.push(
      ...postedSpeedRecords
        .filter(r => r.isOutage)
        .map(r => ({ start: new Date(r.postedAt), end: r.removedAt ? new Date(r.removedAt) : undefined }))
    );
    return {
      outagePeriods: findIntersections(outageDateRanges),
      isError: successfulQueries.length < queryResults.length,
      individualOutageCount: outageDateRanges.length
    };
  }, [postedSpeedRecords, queryResults]);

  if (isError) {
    return <Typography color="error.main">Error loading outage periods</Typography>;
  }

  if (outagePeriods.length === 0 && individualOutageCount > 0) {
    return (
      <Alert severity="warning">
        <div>
          One of the speed limit signs at this location was experiencing technical issues at the date and time searched.
          However, the other sign(s) at that location were working and displaying the speed limit.
        </div>
      </Alert>
    );
  }

  if (outagePeriods.length > 0) {
    return (
      <Alert severity="error">
        <AlertTitle>Outages below are listed only when all signs at a mile marker were not in service</AlertTitle>
        {outagePeriods.map((period, index) => (
          <div key={index}>
            Outage: {formatDate(period.start)} {period.end ? `- ${formatDate(period.end)}` : ""}
          </div>
        ))}
      </Alert>
    );
  }

  return <React.Fragment />;
};

export { OutageListing };
