import * as React from "react";
import { Box } from "@mui/material";
import { parseApiErrorMessage } from "../ApiErrorMessage/ApiErrorMessage";

interface Props {
  error: unknown;
}

const PageError: React.FC<Props> = ({ error }) => {
  return (
    <Box margin={4} sx={{ color: "danger" }}>
      <h3>Oops, something went wrong</h3>
      <p>{parseApiErrorMessage(error, "Unexpected error")}</p>
    </Box>
  );
};

export { PageError };
