import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import timezonePlugin from "dayjs/plugin/timezone";
import { DateRange } from "../types/DateRange";
import { TIME_ZONE } from "../constants/constants";

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

const INFINITY_FUTURE_DATE = new Date(8640000000000000);

/**
 * Formats a date to a string using dayjs
 * @param date
 */
export function formatDate(date: string | Date | undefined): string {
  if (!date) {
    return "";
  }
  return `${dayjs(date).tz(TIME_ZONE).format("M/D/YY h:mma z")}`;
}

/**
 * Finds the intersections between multiple date ranges
 * @param dateRanges
 */
export function findIntersections(dateRanges: DateRange[]): DateRange[] {
  // Handle edge cases
  if (dateRanges.length === 0) {
    return [];
  }
  if (dateRanges.length === 1) {
    return [];
  }

  // Sort the outages by start date
  dateRanges.sort((a, b) => a.start.getTime() - b.start.getTime());

  let commonPeriods: DateRange[] = [];
  let currentPeriod: DateRange = { ...dateRanges[0] };

  for (let i = 1; i < dateRanges.length; i++) {
    const dateRange = dateRanges[i];

    // If there is an overlap between currentPeriod and outage
    if (dateRange.start <= (currentPeriod.end || INFINITY_FUTURE_DATE)) {
      currentPeriod = {
        start: dateRange.start > currentPeriod.start ? dateRange.start : currentPeriod.start,
        end:
          dateRange.end && currentPeriod.end
            ? new Date(Math.min(currentPeriod.end.getTime(), dateRange.end.getTime()))
            : currentPeriod.end || dateRange.end
      };
    } else {
      // If no overlap, reset the current period
      if (currentPeriod.end) {
        commonPeriods.push(currentPeriod);
      }
      currentPeriod = { ...dateRange };
    }
  }

  // Push the last period if valid
  if (currentPeriod.end) {
    commonPeriods.push(currentPeriod);
  } else {
    commonPeriods.push(currentPeriod);
  }

  // Find the actual common periods where all outages overlap
  commonPeriods = commonPeriods.filter(period => {
    return dateRanges.every(
      dateRange =>
        dateRange.start <= (period.end || INFINITY_FUTURE_DATE) &&
        (dateRange.end || INFINITY_FUTURE_DATE) >= period.start
    );
  });

  return commonPeriods;
}

/**
 * Finds the common intersection between multiple date ranges
 * @param dateRanges
 */
export function findDateRangeCommonIntersection(dateRanges: DateRange[]): DateRange | null {
  // Sort the date ranges by start date
  dateRanges.sort((a, b) => a.start.getTime() - b.start.getTime());

  let intersection: DateRange | null = null;

  for (const range of dateRanges) {
    if (!intersection) {
      // If there is no current intersection, start with the first range
      intersection = { ...range };
    } else {
      // Calculate the intersection with the current range
      const intersectionStart = Math.max(intersection.start.getTime(), range.start.getTime());
      const intersectionEnd =
        !intersection.end || !range.end ? undefined : Math.min(intersection.end.getTime(), range.end.getTime());

      if (intersectionEnd === undefined) {
        // If either range is unbounded, the intersection is unbounded
        intersection.start = new Date(intersectionStart);
        intersection.end = undefined;
      } else if (intersectionEnd >= intersectionStart) {
        // If there is an intersection, update the intersection
        intersection.start = new Date(intersectionStart);
        intersection.end = new Date(intersectionEnd);
      } else {
        // If there is no intersection, return null
        return null;
      }
    }
  }

  return intersection;
}
