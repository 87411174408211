/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostedSpeedRecord } from '../models/PostedSpeedRecord';
import type { TimePeriod } from '../models/TimePeriod';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class HistoricDataService {
    /**
     * Finds VSL sign and its value at a given point in time and location
     * @returns PostedSpeedRecord Successful response
     * @throws ApiError
     */
    public static findPostedSpeedRecords({
        state,
        road,
        direction,
        mileMarker,
        timestamp,
    }: {
        state: string,
        road: string,
        direction: string,
        mileMarker: number,
        timestamp: string,
    }): CancelablePromise<Array<PostedSpeedRecord>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/postedSpeedRecords',
            query: {
                'state': state,
                'road': road,
                'direction': direction,
                'mileMarker': mileMarker,
                'timestamp': timestamp,
            },
            errors: {
                404: `No results found`,
            },
        });
    }
    /**
     * Finds VSL sign and its value at a given point in time and location
     * @returns TimePeriod Successful response
     * @throws ApiError
     */
    public static listSignOutages({
        signId,
        from,
        to,
    }: {
        signId: number,
        /**
         * Timestamp in ISO format
         */
        from: string,
        /**
         * Timestamp in ISO format
         */
        to?: string,
    }): CancelablePromise<Array<TimePeriod>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/signOutages/{signId}',
            path: {
                'signId': signId,
            },
            query: {
                'from': from,
                'to': to,
            },
            errors: {
                404: `No results found`,
            },
        });
    }
}
