import * as React from "react";
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { DateTimePickerField } from "components/formikFields/DateTimePickerField/DateTimePickerField";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "react-query";
import { useFormikContext } from "formik";
import { InfrastructureService } from "gen/clients/vsl";
import {
  STATE,
  DIRECTION_NAMES,
  ALL_DIRECTIONAL_EXCEPTION_ROADS,
  DATE_TIME_PICKER_FORMAT,
  TIME_ZONE
} from "constants/constants";
import { SelectOption } from "types/SelectOption";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import dayjs from "dayjs";

interface Props {
  roads: string[];
}

export enum FieldName {
  road = "road",
  direction = "direction",
  mileMarker = "mileMarker",
  timestamp = "timestamp"
}

export interface Values {
  [FieldName.road]: string;
  [FieldName.direction]: string;
  [FieldName.mileMarker]: string;
  [FieldName.timestamp]: string;
}

const SearchForm: React.FC<Props> = ({ roads }) => {
  const { values, isSubmitting } = useFormikContext<Values>();

  const { data: road, error: roadError } = useQuery(["getRoad", values[FieldName.road]], {
    queryFn: () => InfrastructureService.getVslRoad({ state: STATE, road: values[FieldName.road] })
  });

  const roadOptions = React.useMemo(() => roads.map(road => ({ label: road, value: road })), [roads]);

  const directionOptions = React.useMemo(() => {
    if (!road) {
      return [];
    }
    // If the road is an all-directional exception road, then allow users to select any direction.
    if (ALL_DIRECTIONAL_EXCEPTION_ROADS.includes(road.name)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return Object.keys(DIRECTION_NAMES).map(key => ({ label: DIRECTION_NAMES[key], value: key }));
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return road.directions.map(direction => ({ label: DIRECTION_NAMES[direction], value: direction }));
  }, [road]);

  const mileMarkerOptions = React.useMemo(() => {
    if (!road?.minMileMarker || !road?.maxMileMarker) {
      return [];
    }
    const options: SelectOption<number>[] = [];
    for (let i = +road.minMileMarker; i <= +road.maxMileMarker; i = i + 0.1) {
      const sum = Number(i.toPrecision(12));
      options.push({ label: `${sum}`, value: sum });
    }
    return options;
  }, [road]);

  const maxDateTime = React.useMemo(() => {
    const tzFormattedDateString = dayjs
      .tz(new Date(), DATE_TIME_PICKER_FORMAT, TIME_ZONE)
      .format(DATE_TIME_PICKER_FORMAT);
    return dayjs(tzFormattedDateString, { format: DATE_TIME_PICKER_FORMAT });
  }, []);

  React.useEffect(() => {
    if (!road) {
      return;
    }
  }, [road]);

  return (
    <Stack spacing={3}>
      <Typography fontSize={18}>Enter date and time and mile marker to see posted sign speed limit</Typography>
      <>{roadError && <ApiErrorMessage apiError={roadError} />}</>
      <Stack spacing={4}>
        <Box>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} sm={6} md={5} xl={4}>
              <DateTimePickerField
                name={FieldName.timestamp}
                label="Date"
                required={true}
                maxDateTime={maxDateTime}
                helperText="All times are in Eastern Time Zone"
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} sm={8}>
              <SelectOneField name={FieldName.road} label="Route" options={roadOptions} required={true} />
            </Grid>
            <Grid item={true} xs={12} sm={4}>
              <SelectOneField name={FieldName.direction} label="Direction" options={directionOptions} required={true} />
            </Grid>
            <Grid item={true} xs={12} sm={6} md={5} xl={4}>
              <SelectOneField
                name={FieldName.mileMarker}
                label="Mile Marker"
                options={mileMarkerOptions}
                required={true}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Box>
        <Button
          type="submit"
          variant="contained"
          fullWidth={false}
          size="large"
          startIcon={isSubmitting ? undefined : <SearchIcon />}
          sx={{ width: 130 }}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress sx={{ color: "white" }} size={26} /> : "Search"}
        </Button>
      </Box>
    </Stack>
  );
};

export { SearchForm };
