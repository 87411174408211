import * as React from "react";
import { Alert, AlertTitle } from "@mui/material";

interface Props {
  alertTitle?: string;
  children: React.ReactNode;
}

const ErrorMessageAlert: React.FC<Props> = ({ alertTitle, children }) => {
  return (
    <Alert
      severity="error"
      icon={false}
      sx={{ borderRadius: 5, py: 0, my: 1, color: "error.main", fontWeight: "bold" }}
    >
      {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
      {children}
    </Alert>
  );
};

export { ErrorMessageAlert };
