import * as React from "react";
import { ApiError } from "../../gen/clients/vsl";
import { ErrorMessageAlert } from "../ErrorMessageAlert/ErrorMessageAlert";

interface Props {
  apiError: unknown;
}

const parseApiErrorMessage = (apiError: unknown, unexpectedErrorMessage: string): string => {
  if (apiError instanceof ApiError) {
    if (apiError.body?.message) {
      return apiError.body?.errorCode ? apiError.body?.message : apiError.body?.message;
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return apiError?.message || unexpectedErrorMessage;
};

const ApiErrorMessage: React.FC<Props> = ({ apiError }) => {
  return <ErrorMessageAlert>{parseApiErrorMessage(apiError, "Unexpected error")}</ErrorMessageAlert>;
};

export { ApiErrorMessage, parseApiErrorMessage };
