import * as React from "react";
import { Box, CircularProgress } from "@mui/material";

const PageLoading: React.FC = () => {
  return (
    <Box>
      <Box sx={{ display: "flex" }} justifyContent="center" mt={5}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export { PageLoading };
