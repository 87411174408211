import * as React from "react";
import {
  DateTimePicker,
  DateTimePickerProps,
  DateTimeValidationError,
  LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Field, useFormikContext } from "formik";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/material";
import { DATE_TIME_PICKER_FORMAT } from "../../../constants/constants";

interface LocalProps {
  name: string;
  dateTimeFormat?: string;
  label?: string;
  required?: boolean;
  helperText?: string;
}

type Props = LocalProps & DateTimePickerProps<Dayjs>;

const DateTimePickerField: React.FC<Props> = ({
  name,
  dateTimeFormat = DATE_TIME_PICKER_FORMAT,
  label,
  required,
  helperText,
  ...other
}) => {
  const { setFieldValue, validateField, values, errors, isSubmitting } =
    useFormikContext<Record<string, string | null>>();
  const [dateTimePickerError, setDateTimePickerError] = React.useState<DateTimeValidationError | null>();

  const onChange = React.useCallback(
    (value: Dayjs | null) => {
      if (value?.isValid()) {
        const formatedDate = value.format(dateTimeFormat);
        setFieldValue(name, formatedDate);
      }
    },
    [dateTimeFormat, name, setFieldValue]
  );
  const validate = React.useCallback(
    (value: string | null) => {
      if (required && !value) {
        return "Required";
      }
      if (dateTimePickerError) {
        if (dateTimePickerError === "disableFuture") {
          return "Date must be in the past";
        }
        if (dateTimePickerError === "maxDate" || dateTimePickerError === "maxTime") {
          return "Date is beyond the maximum limit";
        }
        if (dateTimePickerError === "invalidDate") {
          return "Invalid format";
        }
        return "Invalid value";
      }
      return undefined;
    },
    [required, dateTimePickerError]
  );

  React.useEffect(() => {
    if (dateTimePickerError !== undefined) {
      validateField(name);
    }
  }, [dateTimePickerError, name, validateField]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Field
        name={name}
        validate={validate}
        required={required}
        render={() => (
          <Stack>
            <DateTimePicker
              {...other}
              label={required ? `${label} *` : label}
              value={values[name] ? dayjs(values[name], { format: dateTimeFormat }) : null}
              disabled={isSubmitting}
              onChange={onChange}
              onError={newError => setDateTimePickerError(newError)}
              timeSteps={{
                minutes: 1
              }}
              slotProps={{
                textField: {
                  error: !!dateTimePickerError || !!errors[name],
                  helperText: errors[name] || helperText || undefined
                }
              }}
            />
          </Stack>
        )}
      ></Field>
    </LocalizationProvider>
  );
};

export { DateTimePickerField };
