import * as React from "react";
import { PostedSpeedRecord } from "gen/clients/vsl";
import { DateRange } from "types/DateRange";
import { findDateRangeCommonIntersection, formatDate } from "utils/DateUtils";
import { ErrorMessageAlert } from "../../../../components/ErrorMessageAlert/ErrorMessageAlert";

interface Props {
  postedSpeedRecords: PostedSpeedRecord[];
}

const OutageResult: React.FC<Props> = ({ postedSpeedRecords }) => {
  const outageDuration: DateRange | null = React.useMemo(() => {
    const dateRanges = postedSpeedRecords.map(record => ({
      start: new Date(record.postedAt!),
      end: record.removedAt ? new Date(record.removedAt) : undefined
    }));
    return findDateRangeCommonIntersection(dateRanges);
  }, [postedSpeedRecords]);

  if (!outageDuration) {
    return <span />;
  }

  return (
    <ErrorMessageAlert alertTitle="There was an outage during the date and time you searched.">
      Outage: {formatDate(outageDuration.start)} {outageDuration.end && ` - ${formatDate(outageDuration.end)}`}
    </ErrorMessageAlert>
  );
};

export { OutageResult };
