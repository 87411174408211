import * as React from "react";
import { PostedSpeedRecord } from "gen/clients/vsl";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { formatDate } from "../../../../utils/DateUtils";
import { OutageListing } from "./OutageListing";

interface Props {
  postedSpeedRecords: PostedSpeedRecord[];
}

const PostedSpeedResult: React.FC<Props> = ({ postedSpeedRecords }) => {
  const speedRecord = React.useMemo(() => {
    return postedSpeedRecords.find(record => !record.isOutage);
  }, [postedSpeedRecords]);

  if (!speedRecord) {
    return <>No data</>;
  }

  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <Box width={200} pt={2} border={4} borderRadius={3} borderColor="black">
        <Stack spacing={2}>
          <Typography textAlign="center" fontSize={48} fontWeight="bold" lineHeight={0.8}>
            SPEED
          </Typography>
          <Typography textAlign="center" fontSize={48} fontWeight="bold" lineHeight={0.8}>
            LIMIT
          </Typography>
          <Box padding={1}>
            <Box
              textAlign="center"
              color="white"
              bgcolor="black"
              fontSize={75}
              lineHeight={1.3}
              fontWeight="bold"
              borderRadius={1}
            >
              {speedRecord.value}
            </Box>
          </Box>
        </Stack>
      </Box>
      <Divider orientation="vertical" flexItem={true} />
      <Stack>
        <Typography fontSize="x-large" color="gray">
          Posted:
        </Typography>
        <Stack spacing={2}>
          <Stack fontSize="x-large" fontWeight="bold">
            {formatDate(speedRecord.postedAt)}
            {speedRecord.removedAt && (
              <>
                <span>to</span>
                <span>{formatDate(speedRecord.removedAt)}</span>
              </>
            )}
          </Stack>
          <OutageListing postedSpeedRecords={postedSpeedRecords} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export { PostedSpeedResult };
