import * as React from "react";
import { PostedSpeedRecord } from "gen/clients/vsl";
import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import { DIRECTION_NAMES } from "constants/constants";
import { PageLayout } from "components/PageLayout/PageLayout";
import { PostedSpeedResult } from "./PostedSpeedResult/PostedSpeedResult";
import { formatDate } from "utils/DateUtils";
import logoImage from "assets/logo.png";
import PrintIcon from "@mui/icons-material/Print";
import { OutageResult } from "./OutageResult/OutageResult";

interface Props {
  postedSpeedRecords: PostedSpeedRecord[];
  selectedDirection: string;
  onReturnToSearchClick: () => void;
}

const ResultsPanel: React.FC<Props> = ({ postedSpeedRecords, selectedDirection, onReturnToSearchClick }) => {
  const isOutage = React.useMemo(() => {
    return !postedSpeedRecords.some(record => !record.isOutage);
  }, [postedSpeedRecords]);

  if (postedSpeedRecords.length === 0) {
    return <>No data</>;
  }

  return (
    <PageLayout containerMaxWidth="md">
      <Stack direction="row" justifyContent="space-between" mb={2} displayPrint="none">
        <Button sx={{ textTransform: "none" }} onClick={onReturnToSearchClick}>
          &lt; Return to Search
        </Button>
        <Button variant="contained" color="primary" startIcon={<PrintIcon />} onClick={window.print}>
          Print Sign
        </Button>
      </Stack>
      <Card>
        <CardContent>
          <Stack spacing={4}>
            {/* ---------------------- Map --------------------*/}
            <img
              alt={"Map"}
              src={`https://maps.googleapis.com/maps/api/staticmap?size=820x300&zoom=14&style=feature:poi|visibility:off&key=${
                process.env.REACT_APP_GOOGLE_MAPS_API_KEY
              }&path=weight:12%7Ccolor:blue%7Cenc:${postedSpeedRecords[0].path || ""}`}
            />
            {/* --------------------- Title section --------------------- */}
            <Stack spacing={1}>
              <Typography variant="h4" fontWeight="bold">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore*/}
                {postedSpeedRecords[0].road} {DIRECTION_NAMES[selectedDirection]}: Mile range{" "}
                {postedSpeedRecords[0].startMileMarker}-{postedSpeedRecords[0].endMileMarker} mi
              </Typography>
              <Typography fontSize="x-large" color="gray">
                Sign IDs:{" "}
                {postedSpeedRecords
                  .map(record => record.vslSignId)
                  .sort((a, b) => a - b)
                  .join(", ")}
              </Typography>
            </Stack>
            <Divider />
            {/* ----------------- Posted speed section ------------------ */}
            {isOutage ? (
              <OutageResult postedSpeedRecords={postedSpeedRecords} />
            ) : (
              <PostedSpeedResult postedSpeedRecords={postedSpeedRecords} />
            )}
            <Divider />
            {/* ----------------- Request information section --------------- */}
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} sm={4}>
                <Typography fontSize="x-large" color="gray">
                  For Date:
                </Typography>
                <Typography fontSize="large" fontWeight="bold" lineHeight={3}>
                  {formatDate(postedSpeedRecords[0].timestamp)}
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={4}>
                <Typography fontSize="x-large" color="gray">
                  For Route:
                </Typography>
                <Typography fontSize="large" fontWeight="bold" lineHeight={3}>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore*/}
                  {postedSpeedRecords[0].road} {DIRECTION_NAMES[selectedDirection]}
                </Typography>
              </Grid>
              <Grid item={true} xs={12} sm={4}>
                <Typography fontSize="x-large" color="gray">
                  For Mile Marker:
                </Typography>
                <Typography fontSize="large" fontWeight="bold" lineHeight={3}>
                  {postedSpeedRecords[0].mileMarker}
                </Typography>
              </Grid>
            </Grid>
            {/* -------------------- Footer section --------------------- */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
              pt={10}
              color="gray"
              fontSize="smaller"
            >
              <Stack>
                <span>Queried: {formatDate(new Date())}</span>
                <span>URL: {document.location.href}</span>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="flex-end">
                <Box textAlign="right">Information provided by INDOT {new Date().getFullYear()}.</Box>
                <Box component="img" src={logoImage} alt="Logo" width={40} height={40} />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </PageLayout>
  );
};

export { ResultsPanel };
