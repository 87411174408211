import * as React from "react";
import { FormControl, MenuItem } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { SelectOption } from "../../../types/SelectOption";

interface LocalProps {
  name: string;
  label: string;
  options: SelectOption<string | number>[];
  required?: boolean;
}

type Props = LocalProps & TextFieldProps;

const SelectOneField: React.FC<Props> = ({ name, label, options, required, ...other }) => {
  return (
    <FormControl fullWidth>
      <Field
        {...other}
        component={TextField}
        type="text"
        name={name}
        label={label}
        select={true}
        required={required}
        validate={(value: string) => (required && !value ? "Required" : undefined)}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

export { SelectOneField };
